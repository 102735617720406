<template>
    <div class="alert alert-secondary" :class="optional_class" role="alert">
        <template v-if="icon"><i class='bi' :class="[icon, icon_class]"></i></template>
        {{ text }}
        <slot/>
    </div>
</template>

<script>
export default {
    name: 'AlertNoRecord',
    props: {
        text: {
            type: String,
            default: 'データがありません'
        },
        optional_class: {
            type: String,
            default: ''
        },
        icon: {
            type: String,
            default: ''
        },
        icon_class: {
            type: String,
            default: ''
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .alert { padding: 0.5rem 0.5rem; margin-bottom: 0; }
</style>
