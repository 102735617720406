/**
 * 規格前置単位 エンティティ
 */
import { isNud } from '@/utilities/typing'

class SpecPrefix {
    spec_prefix_id;
    prefix_name;

    constructor(properties) {
        if (isNud(properties)) {
            properties = {};
        }

        this.spec_prefix_id = properties.spec_prefix_id;
        this.prefix_name = properties.prefix_name;
    }
}

export default SpecPrefix;
