<template>
    <div class="table-responsive">
        <table class="table table-hover" :class="optional_class">
            <slot/>
        </table>
    </div>
</template>

<script>
export default {
    name: 'TableNormal',
    props: {
        optional_class: {
            type: String,
            default: ''
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .table ::v-slotted(thead) {border-top: 2px solid currentColor;}
    .table ::v-slotted(th) {text-align: center; vertical-align: middle;}
    .table ::v-slotted(td) {vertical-align: middle;}
    .table ::v-slotted(tr) { border-top: none; }
    .table ::v-slotted(th:first-child), .table  ::v-slotted(td:first-child) {border-left: none !important;}
    .table ::v-slotted(tr th:last-child), .table  ::v-slotted(tr td:last-child) {border-right: none !important;}

    .table-fixed {table-layout: fixed;}
    .table-auto {width: auto;}
</style>
