<template>
    <page-title
        icon="bi-gear-fill"
        title="規格単位マスタ"
    >
    </page-title>

    <section class="section">
        <ul class="nav nav-tabs mb-4">
            <li class="nav-item">
                <a
                    class="nav-link pointer"
                    :class="{'active': display_prefix, 'disabled': loading}"
                    @click="activatePrefix();"
                >
                    前置単位
                </a>
            </li>
            <li class="nav-item">
                <a
                    class="nav-link pointer"
                    :class="{'active': display_suffix, 'disabled': loading}"
                    @click="activateSuffix();"
                >
                    後置単位
                </a>
            </li>
        </ul>
        <form @submit.prevent="search()" class="row align-items-end">
            <div class="form-group col-md-8">
                <label>単位名</label>
                <form-input
                    v-model="condition_name"
                ></form-input>
            </div>
            <div class="col-md-4">
                <button-search
                    text="絞り込み"
                    type="submit"
                    :optional_class="{'disabled': loading}"
                    :disabled="loading"
                ></button-search>
            </div>
        </form>
    </section>

    <section class="section" v-if="display_prefix">
        <inline-loader v-if="loading"></inline-loader>
        <template v-else>
            <table-normal class="table" optional_class="table-auto">
                <thead>
                    <tr class="table-dark">
                        <th class="col text-center">単位名</th>
                        <th class="col" v-if="$store.getters['auth/canManageStandardSpecLabel']()"></th>
                        <th class="col" v-if="$store.getters['auth/canManageStandardSpecLabel']()"></th>
                    </tr>
                </thead>
                <tbody>
                    <template v-if="specs.length">
                        <tr v-for="(spec, index) in specs" :key="spec.spec_prefix_id">
                            <td class="col align-middle" style="min-width: 300px">
                                <form-input
                                    v-if="$store.getters['auth/canManageStandardSpecLabel']()"
                                    v-model="spec.prefix_name"
                                    :required="true"
                                    maxlength="100"
                                    :form="`spec_prefix_${spec.spec_prefix_id}`"
                                ></form-input>
                                <read-only
                                    v-else
                                    :text="spec.prefix_name"
                                    class="text-center"
                                ></read-only>
                            </td>
                            <td class="col align-middle text-end" v-if="$store.getters['auth/canManageStandardSpecLabel']()">
                                <form @submit.prevent="updatePrefix(spec)" :id="`spec_prefix_${spec.spec_prefix_id}`">
                                    <button-exec-update
                                        text="保存"
                                    ></button-exec-update>
                                </form>
                            </td>
                            <td class="col align-middle" v-if="$store.getters['auth/canManageStandardSpecLabel']()">
                                <button-exec-delete
                                    type="button"
                                    @click="$refs.confirm_remove_prefix.show(); setTargetSpecPrefix(spec, index)"
                                ></button-exec-delete>
                            </td>
                        </tr>
                    </template>
                    <template v-else>
                        <tr>
                            <td colspan="2"><alert-no-record></alert-no-record></td>
                        </tr>
                    </template>
                </tbody>
                <tfoot v-if="$store.getters['auth/canManageStandardSpecLabel']()">
                    <tr>
                        <td class="align-middle">
                            <form-input
                                v-if="$store.getters['auth/canManageStandardSpecLabel']()"
                                v-model="additional_spec_prefix.prefix_name"
                                :required="true"
                                maxlength="100"
                                form="add_spec_prefix"
                            ></form-input>
                        </td>
                        <td class="align-middle text-end">
                            <template v-if="$store.getters['auth/canManageStandardSpecLabel']()">
                            <form @submit.prevent="createPrefix()" id="add_spec_prefix">
                                <button-exec-create
                                    text="追加"
                                ></button-exec-create>
                            </form>
                            </template>
                        </td>
                    </tr>
                </tfoot>
            </table-normal>
        </template>
    </section>

    <section class="section" v-if="display_suffix">
        <inline-loader v-if="loading"></inline-loader>
        <template v-else>
            <table-normal class="table" optional_class="table-auto">
                <thead>
                    <tr class="table-dark">
                        <th class="col text-center">単位名</th>
                        <th class="col" v-if="$store.getters['auth/canManageStandardSpecLabel']()"></th>
                        <th class="col" v-if="$store.getters['auth/canManageStandardSpecLabel']()"></th>
                    </tr>
                </thead>
                <tbody>
                    <template v-if="specs.length">
                        <tr v-for="(spec, index) in specs" :key="spec.spec_suffix_id">
                            <td class="col align-middle" style="min-width: 300px">
                                <form-input
                                    v-if="$store.getters['auth/canManageStandardSpecLabel']()"
                                    v-model="spec.suffix_name"
                                    :required="true"
                                    maxlength="100"
                                    :form="`spec_suffix_${spec.spec_suffix_id}`"
                                ></form-input>
                                <read-only
                                    v-else
                                    :text="spec.suffix_name"
                                    class="text-center"
                                ></read-only>
                            </td>
                            <td class="col align-middle text-end" v-if="$store.getters['auth/canManageStandardSpecLabel']()">
                                <form @submit.prevent="updateSuffix(spec)" :id="`spec_suffix_${spec.spec_suffix_id}`">
                                    <button-exec-update
                                        text="保存"
                                    ></button-exec-update>
                                </form>
                            </td>
                            <td class="col align-middle" v-if="$store.getters['auth/canManageStandardSpecLabel']()">
                                <button-exec-delete
                                    type="button"
                                    @click="$refs.confirm_remove_suffix.show(); setTargetSpecSuffix(spec, index)"
                                ></button-exec-delete>
                            </td>
                        </tr>
                    </template>
                    <template v-else>
                        <tr>
                            <td colspan="2"><alert-no-record></alert-no-record></td>
                        </tr>
                    </template>
                </tbody>
                <tfoot v-if="$store.getters['auth/canManageStandardSpecLabel']()">
                    <tr>
                        <td class="align-middle">
                            <form-input
                                v-if="$store.getters['auth/canManageStandardSpecLabel']()"
                                v-model="additional_spec_suffix.suffix_name"
                                :required="true"
                                maxlength="100"
                                form="add_spec_suffix"
                            ></form-input>
                        </td>
                        <td class="align-middle text-end">
                            <template v-if="$store.getters['auth/canManageStandardSpecLabel']()">
                            <form @submit.prevent="createSuffix()" id="add_spec_suffix">
                                <button-exec-create
                                    text="追加"
                                ></button-exec-create>
                            </form>
                            </template>
                        </td>
                    </tr>
                </tfoot>
            </table-normal>
        </template>
    </section>

    <confirm-dialog ref="confirm_remove_prefix" @ok="removePrefix()">
        <p>削除してよろしいですか？</p>
    </confirm-dialog>
    <confirm-dialog ref="confirm_remove_suffix" @ok="removeSuffix()">
        <p>削除してよろしいですか？</p>
    </confirm-dialog>

</template>

<script>
import SpecPrefix from '@/models/entities/spec-prefix';
import SpecSuffix from '@/models/entities/spec-suffix';
import ConfirmDialog from '@/components/tools/ConfirmDialog.vue';
import InlineLoader from '@/components/tools/InlineLoader.vue';
import FormInput from '@/components/forms/FormInput';
import ReadOnly from '@/components/forms/ReadOnly';
import ButtonSearch from '@/components/buttons/ButtonSearch';
import ButtonExecCreate from '@/components/buttons/ButtonExecCreate';
import ButtonExecUpdate from '@/components/buttons/ButtonExecUpdate';
import ButtonExecDelete from '@/components/buttons/ButtonExecDelete';
import TableNormal from '@/components/tables/TableNormal.vue';
import AlertNoRecord from '@/components/notice/AlertNoRecord.vue';
import PageTitle from '@/components/PageTitle.vue';

export default {
    name: 'SpecUnit',
    components: {
        ConfirmDialog,
        InlineLoader,
        FormInput,
        ReadOnly,
        ButtonSearch,
        ButtonExecCreate,
        ButtonExecUpdate,
        ButtonExecDelete,
        TableNormal,
        AlertNoRecord,
        PageTitle,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showMessage',
    ],
    data() {
        return {
            //部分ローダー
            loading: false,

            //表示フラグ
            display_prefix: true,
            display_suffix: false,

            //検索オプション
            condition_name: null,
            condition: {
                prefix_name: null,
                suffix_name: null,
            },

            //表示データ
            specs: [],
            api_root: null,

            //新規登録規格前置単位
            additional_spec_prefix: new SpecPrefix(),
            //新規登録規格後置単位
            additional_spec_suffix: new SpecSuffix(),
            //削除対象規格前置単位
            delete_target_prefix: {
                spec_prefix: new SpecPrefix(),
                index: null,
            },
            //削除対象規格後置単位
            delete_target_suffix: {
                spec_suffix: new SpecSuffix(),
                index: null,
            },
        }
    },
    mounted() {
        //storeに前回の検索条件があれば
        this.condition = this.$store.getters['condition/merge']('SpecUnit', this.condition);
        this.search();
    },
    computed: {
    },
    methods: {
        //前置単位を表示
        activatePrefix() {
            this.display_prefix = true;
            this.display_suffix = false;
            this.condition_name = this.condition.prefix_name;
            this.search();
        },
        //後置単位を表示
        activateSuffix() {
            this.display_prefix = false;
            this.display_suffix = true;
            this.condition_name = this.condition.suffix_name;
            this.search();
        },
        //検索
        search() {
            //表示データ初期化
            this.specs.splice(0);
            //部分ローダー
            this.loading = true;

            //api root 判定
            if (this.display_prefix) {
                this.api_root = '/spec-prefix';
                this.condition.prefix_name = this.condition_name;
            } else {
                this.api_root = '/spec-suffix';
                this.condition.suffix_name = this.condition_name;
            }

            this.$http.get(this.api_root, {
                params: this.condition
            })
            .then(response => {
                for (let row of response.data) {
                    if (this.display_prefix) {
                        this.specs.push(new SpecPrefix(row));
                    } else {
                        this.specs.push(new SpecSuffix(row));
                    }
                }
                //検索条件をセッション保存
                this.$store.commit('condition/setSearchCondition', {
                    page: 'SpecUnit',
                    condition: this.condition,
                });
            })
            .finally(() => {
                this.loading = false;
            });
        },

        //削除対象の規格前置単位をセットする
        setTargetSpecPrefix(spec_prefix = new SpecPrefix(), index = null) {
            this.delete_target_prefix.spec_prefix = spec_prefix;
            this.delete_target_prefix.index = index;
        },
        //削除対象の規格後置単位をセットする
        setTargetSpecSuffix(spec_suffix = new SpecSuffix(), index = null) {
            this.delete_target_suffix.spec_suffix = spec_suffix;
            this.delete_target_suffix.index = index;
        },

        //前置単位新規登録
        createPrefix() {
            this.startScreenLoading();

            this.$http.post('/spec-prefix', this.additional_spec_prefix)
            .then(() => {
                this.additional_spec_prefix.prefix_name = null;
                this.search();
                this.showMessage('追加しました');
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
        //後置単位新規登録
        createSuffix() {
            this.startScreenLoading();

            this.$http.post('/spec-suffix', this.additional_spec_suffix)
            .then(() => {
                this.additional_spec_suffix.suffix_name = null;
                this.search();
                this.showMessage('追加しました');
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },

        //前置単位編集
        updatePrefix(spec_prefix) {
            this.startScreenLoading();

            this.$http.put(`/spec-prefix/${spec_prefix.spec_prefix_id}`, spec_prefix)
            .then(() => {
                this.showMessage('編集しました');
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
        //後置単位編集
        updateSuffix(spec_suffix) {
            this.startScreenLoading();

            this.$http.put(`/spec-suffix/${spec_suffix.spec_suffix_id}`, spec_suffix)
            .then(() => {
                this.showMessage('編集しました');
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },

        //前置単位削除
        removePrefix() {
            this.startScreenLoading();

            this.$http.delete(`/spec-prefix/${this.delete_target_prefix.spec_prefix.spec_prefix_id}`)
            .then(() => {
                this.specs.splice(this.delete_target_prefix.index, 1);
                this.showMessage('削除しました');

                //値をリセット
                this.setTargetSpecPrefix();
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
        //後置単位削除
        removeSuffix() {
            this.startScreenLoading();

            this.$http.delete(`/spec-suffix/${this.delete_target_suffix.spec_suffix.spec_suffix_id}`)
            .then(() => {
                this.specs.splice(this.delete_target_suffix.index, 1);
                this.showMessage('削除しました');

                //値をリセット
                this.setTargetSpecSuffix();
            })
            .finally(() => {
                this.endScreenLoading();
            });
        }
    }
}
</script>

<style scoped>
    .pointer {cursor: pointer}
</style>
