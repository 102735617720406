/**
 * 規格後置単位 エンティティ
 */
import { isNud } from '@/utilities/typing'

class SpecSuffix {
    spec_suffix_id;
    suffix_name;

    constructor(properties) {
        if (isNud(properties)) {
            properties = {};
        }

        this.spec_suffix_id = properties.spec_suffix_id;
        this.suffix_name = properties.suffix_name;
    }
}

export default SpecSuffix;
